<template>
<main>

  <div class="container-fluid wrapper-medium pt-5 pt-lg-0" v-if="questionnaireData !== null">
    <div class="row mb-5">
      <div class="col-12 text-center">
        <h1 class="anim-load1">
          Hello {{questionnaireData.parentFirstName}},
        </h1>
        <div>
          <h3 class="mt-3">Please complete the TAYC-R questionnaire below for {{questionnaireData.childFirstName}}</h3>
        </div>
        <p>This TAYC-R Questionnaire was created on: {{ $date(questionnaireData.sentOn).format('DD MMMM YYYY') }}</p>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-small" v-if="status == 1 && taycrQuestions.length">
    <div class="row mb-5 wrapper-small">
      <div class="col-12">
        <ValidationObserver ref="form" class="border-bottom mb-2 pb-2">
          <b-form @submit.prevent class="mb-3" aria-label="Questionnaire">

            <div class="form-group mb-4" v-for="(item, index) in taycrQuestions" :ref="'q' + index" v-bind:key="item.id" :aria-label="item.text" role="radiogroup">
              <label>{{index + 1}}. {{item.text}}</label>
              <ValidationProvider v-slot="{classes, errors}" rules="required" :name="'Question' + index">
                <Score :questionId="index" :initValue="null" v-on:scoreChange="scoreUpdate($event)" v-model="form['q' + index]" v-bind:class="classes" />
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <div class="row">
              <div class="col-12">
                  <p>If any questions feel difficult to answer then please get in touch with your therapist to discuss.</p>
                  <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center mb-4">
                <button v-on:click="submit()" type="submit" class="btn btn-warning btn-lg btn-block">Submit</button>
              </div>
            </div>

          </b-form>
        </ValidationObserver>

      </div>
    </div>
  </div>

  <Footer />
</main>

</template>

<script>
import {surveyFields} from '@/surveyFields'
import Score from "@/components/modules/Score.vue"

export default {
  name: 'SurveyTaycr',
  components: {Score },
  data () {
    return {
      id: null,
      profile: null,
      taycrQuestions: [],
      status: null,
      form: {
        q0: null,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        q9: null,
        q10: null
      },
      questionnaireData: null
    }
  },
  computed: {
    formComp () {
      return this.$refs.form;
    }
  },
  async created() {
    this.$store.state.loadingProgress = true
    this.$store.state.appSection = 'survey'
    this.id = this.$route.query.id || null
    this.profile = this.$route.query.profile || null
    if (this.id !== null && this.profile !== null) {
      await this.getQuestionnaireStatusGet()
      this.taycrQuestions = surveyFields.taycrQuestions;
    } else {
      // Block access to this page if id and profile not present in url
      this.$router.push('/')
    }
  },

  methods: {
    scoreUpdate(data) {
      this.form[data.q + ''] = parseInt(data.score, 10);
    },

    getQuestionnaireStatusGet() {
      return this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireStatusGet,
        params: {
          profileId: this.profile,
          questionnaireId: this.id
        }
      })
        .then((response) => {
          this.status = response.data
          // If invalid or error, go to homepage. Otherwise let data build the layout
          if (this.status != 1 && this.status != 2) {
            this.alertMsgShow("Invalid questionnaire", false)
            this.$router.push('/')
          } else if (this.status == 2) {
            this.$router.push(`/taycr/result?id=${this.id}&profile=${this.profile}`)
          } else if (this.status == 1) {
            this.getQuestionnaireData()
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Invalid questionnaire", false)
          this.$router.push('/')
        })
    },

    getQuestionnaireData() {
      this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireGet,
        params: {
          id: this.id,
          profileId: this.profile
        }
      })
        .then((response) => {
          console.log('getQuestionnaire', response.data)
          this.questionnaireData = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
        .finally(() => this.$store.state.loadingProgress = false)
    },

     async submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          var data = {
            childDifficultyUnderstanding: this.form.q0,
            childBehaviourUnderstanding: this.form.q1,
            childManagementConfidence: this.form.q2,
            childChallengeSkills: this.form.q3,
            childRelationshipRating: this.form.q4,
            childCommunicationRating: this.form.q5,
            childHelpResponsiveness: this.form.q6,
            easeOfChildCare: this.form.q7,
            easeOfRelationshipBuilding: this.form.q8,
            childRewardingRating: this.form.q9,
            placementSecurityRating: this.form.q10,
          }

          this.$http({
            method: 'post', 
            url: this.$api.QuestionnaireSubmit,
            params: {
              id: this.id,
              profileId: this.profile
            },
            data: data
          })
            .then((response) => {
              console.log(response)
              this.$router.push(`/taycr/result?id=${this.id}&profile=${this.profile}`)
            })
            .catch((error) => {
              console.error('Error', error.response.data)
              if (error.response.status == 500) {
                this.alertMsgShow('This questionnaire has been deleted. Please contact your therapist.', false);
                this.$router.push('/')
              } else {
                this.alertMsgShow('Something went wrong. Please contact your therapist.', false);
              }
            })
            .finally(() => {
              this.$store.state.loadingProgress = false
            })        
        }
      });
    },

  },
  watch: {
  }
}
</script>
