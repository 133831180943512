<template>
  <div class="score form-control mb-1">
    <span class="score-label">Not at all</span>
    <div class="score-field">
      <input type="radio" :id="'scoreRadio1' + questionId" :name="questionIdComp" value="1" v-model="scoreResult" />
      <label :for="'scoreRadio1' + questionId">1</label>

      <input type="radio" :id="'scoreRadio2' + questionId" :name="questionIdComp" value="2" v-model="scoreResult" />
      <label :for="'scoreRadio2' + questionId">2</label>

      <input type="radio" :id="'scoreRadio3' + questionId" :name="questionIdComp" value="3" v-model="scoreResult" />
      <label :for="'scoreRadio3' + questionId">3</label>

      <input type="radio" :id="'scoreRadio4' + questionId" :name="questionIdComp" value="4" v-model="scoreResult" />
      <label :for="'scoreRadio4' + questionId">4</label>

      <input type="radio" :id="'scoreRadio5' + questionId" :name="questionIdComp" value="5" v-model="scoreResult" />
      <label :for="'scoreRadio5' + questionId">5</label>

    </div>
    <span class="score-label">A lot</span>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: ['questionId', 'initValue'],
  data: function () {
    return {
      scoreResult: null
    }
  },
  computed: {
    questionIdComp () {
      return this.questionId;
    }
  },
  created () {
    this.scoreResult = this.initValue;
  },
  watch: {
    scoreResult () {
      this.$emit('scoreChange', {q: ('q' + this.questionIdComp), score: this.scoreResult})
    },
    initValue () {
      this.scoreResult = this.initValue;
    }
  }
}
</script>
